import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { sizing } from '@material-ui/system';
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  TableCell,
  Button,
  FormControl,
  Select,
  Paper,
  Grid,
  TablePagination,
  Modal,
  IconButton,
} from '@material-ui/core';

import TablePaginationActions from '../components/TablePaginationActions';
import {
  StyledTableRow,
  StyledTableCell,
} from '../../common/components/StyledTable';
import styles from '../../../css/baseStyle';

// Constants
var BrandArrayID = 0;
var ProductArrayID = 0;

//Main
const SubmissionList = (props) => {
  
  //States
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [displayModel, setDisplayModel] = React.useState(false);
  const [brandId, setBrandId] = React.useState(0);
  const [productId, setProductId] = React.useState(0);
  const [update, setUpdate] = React.useState(0);
  const _affiliateCode =  window.location.pathname.split("/")[1]
  // Functions/Events
  const fetchFormData = (formType) => {
    return;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProductSelection = (event, id, brandArrayID) => {
    console.log(brandArrayID);
    console.log(event.target.value - 1);
    setBrandId(brandArrayID);
    setProductId(event.target.value - 1);

    console.log(brandId);
    console.log(productId);
    setDisplayModel(true);
  };
  const handleModelOpen = () => {
    setDisplayModel(true);
  };

  const handleModelClose = () => {
    setDisplayModel(false);
  };

  // Output
  return (
    <div>
      <Paper elevation={4} className={styles.containerPaperTable}>
        {/* <p id="product-description">donde esta la biblioteca</p> */}
        <Grid container className={styles.containerMargin}>
          <Grid container item spacing={1} xs={6}></Grid>
          <Grid
            container
            item
            spacing={1}
            xs={6}
            direction="row-reverse"
            justify="flex-start"
            className={styles.containerRightPadding}
          >
            Total Submissions:{props.Orders.length}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table
            className={styles.table}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Order Id</StyledTableCell>
                <StyledTableCell align="center">HCP Name</StyledTableCell>
                <StyledTableCell align="center">HCP Id</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.Orders.length > 0 ? (
                (rowsPerPage > 0
                  ? props.Orders.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.Orders
                ).map((order, i) => (
                  <StyledTableRow key={i} style={{height:"1px !important"}}>
                    <StyledTableCell align="center">
                      {`HCP-SP-${order.order_id}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {order.hcp_name}
                    </StyledTableCell>
                    <StyledTableCell align="center" >
                      {order.hcp_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {order.status? order.status : "N/A"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                     <IconButton
                        component={Link}
                        // onClick={(event) => history.push(`Form${urlPart}/${order.ID}`)}
                        to={order.status === 'Draft' ? `spForm/edit/${order.order_id}` : `spForm/view/${order.order_id}`}
                        classes={{
                          root: styles.PaddingLessIconButton,
                        }}
                      >
                        <VisibilityIcon fontSize='small' color='error' />
                    </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow className={styles.labelTextContainer}>
                  <TableCell>No Products added yet.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={props.Orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

// Exports
export default SubmissionList;
