import DateFnsUtils from "@date-io/date-fns";
import uniqid from "uniqid";

import {
  createTheme,
  FormControlLabel,
  Hidden,
  InputLabel,
  Grid,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import styles from "../../../css/baseStyle.css";
const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#d52b1e", //#d52b1e
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#6c757d",
      },
      daySelected: {
        backgroundColor: red["400"],
      },
      dayDisabled: {
        color: red["100"],
      },
      current: {
        color: red["900"],
      },
    },
    MuiButton: {
      textPrimary: {
        color: red["400"],
      },
    },
    // MuiPickersModal: {
    //     dialogAction: {
    //         color: red['400'],
    //     },
    // },
  },
});

//#region functions

//#endregion
const formDataPicker = (props) => {
  //#region  States
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  // const [right, setRight] = React.useState(props.value ?? props.defaultValue);

  //#endregion

  //#region function and declaration
  const generateDeviceControl = (props) => {
    if (props.deviceControl == null) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl
            variant='outlined'
            margin='dense'
            size='small'
            disabled={props.isDisabled ?? false}
            className={`${styles.GlobalFormControlInputDevice}`}
          >
            {props.isDisabled ? (
              ""
            ) : (
              <InputLabel id={`${props.id ?? ""}_label`}>
                {/* {props.label ?? "No Label"} */}
                {''}
                </InputLabel>
            )}
            <KeyboardDatePicker
              id={`${props.id ?? uniqid()}_D`}
              format={props.format ?? 'MM/dd/yyyy'}
              size='small'
              autoComplete="off"
              autoOk
              error={error || props.error}
              clearable={props.clearable ?? true}
              // inputVariant='outlined'
              value={props.value}
              label={
                <>
                    <span>{`${props.label ?? "No Label"}`}</span>
                    {props.isRequired ? (
                      <span>*</span>
                    ) : (
                      ""
                    )}
                  </>
              }
              disabled={props.isDisabled ?? false}
              shouldDisableDate={
                props.shouldDisableDate
                  ? props.shouldDisableDate
                  : (day) => {
                      return false;
                    }
              }
              onChange={props.setValue}
              disablePast={props.disablePast ?? false}
              minDate={props.minDate?? ''}
              invalidDateMessage=""
            />
            <FormHelperText
              className={
                props.error ? styles.ColorTextPrimary : styles.blackText
              }
            >
              { props.error
                ?  props.helperText: '' }
            </FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
      );
    } else {
      return props.deviceControl;
    }
  };
  const generateControl = (props) => {
    if (props.control == null) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl
            error={error || props.error}
            size='small'
            disabled={props.isDisabled ?? false}
            className={styles.GlobalFormControlInput}
          >
            <KeyboardDatePicker
              id={`${props.id ?? uniqid()}_D`}
              format={props.format ?? "MM/dd/yyyy"}
              size='small'
              autoOk
              autoComplete="off"
              error={error || props.error}
              clearable={props.clearable ?? true}
              inputVariant='outlined'
              disabled={props.isDisabled ?? false}
              value={props.value}
              invalidDateMessage=""
              shouldDisableDate={
                props.shouldDisableDate
                  ? props.shouldDisableDate
                  : (day) => {
                      return false;
                    }
              }
              onChange={props.setValue}
              disablePast={props.disablePast ?? false}
            />
            <FormHelperText
              className={
                props.error ? styles.ColorTextPrimary : styles.blackText
              }
            >
              {props.value && props.error
                ? "props.helperText": '' }
            </FormHelperText>
          </FormControl>
        </MuiPickersUtilsProvider>
      );
    } else {
      return props.control;
    }
  };

  //#endregion

  //#region mainOutput
  return (
    <>
      {
        props.controlType == "DeviceOnly" ? (
          generateDeviceControl(props)
        ) : (
          <ThemeProvider theme={materialTheme}>
            <Hidden smDown>
              <FormControlLabel
                id={props.id ?? ""}
                control={generateControl(props)}
                disabled={props.isDisabled ?? false}
                label={
                  <>
                    <span>{`${props.label ?? "No Label"}`}</span>
                    {props.isRequired ? (
                      <span className={styles.ColorTextPrimary}>*</span>
                    ) : (
                      ""
                    )}
                  </>
                }
                labelPlacement='start'
                classes={{
                  root: styles.GlobalFormControlAlignLeft,
                  label:props.placetext ? styles.GlobalNoFormControlLabel: styles.GlobalFormControlLabel,
                }}
              />
            </Hidden>
            <Hidden mdUp>{generateDeviceControl(props)}</Hidden>
          </ThemeProvider>
        )
        //#endregion
      }
    </>
  );
  //#endregion
};

export default formDataPicker;