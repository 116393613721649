//import GridOverlay from '@material-ui/data-grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  load: {
    position: "fixed",
    height: "100%",
    width: "100%",
    margin: 0,
    top: "0",
    left: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 1111,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    opacity: "50%",
  },
}));
const CustomFullLoader = () => {
  const classes = useStyle();
  return (
    // <GridOverlay>
    <div className={classes.load}>
      <CircularProgress
        className={classes.loader}
        size="4rem"
        style={{
          width: "80px",
          height: "80px",
          borderTopColor: "red",
          color: "red",
        }}
      />
    </div>
    // </GridOverlay>
  );
};

export default CustomFullLoader;