import React from 'react';
import ReactDOM from 'react-dom';

import styles from '../../../../css/baseStyle.css';
import FormInputBlock from '../../../common/components/formInputBlock';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import {
  GetSessionInformation,
  SetSessionInformationHCP,
} from '../../../../Store/Slices/SessionSlice';
import {
  Button,
  Typography,
  Paper,
  FormHelperText,
  FormControlLabel,
  Chip,
  Grid,
  Checkbox,
  StylesProvider,
  Snackbar,
  Hidden,
} from '@material-ui/core';

const from_server = {};

const HCPHome = (props) => {
  //#region componenets states2
  const [hcpName, setHcpName] = React.useState('Jivesh');
  const [hcpID, setHcpID] = React.useState('');
  const [hcpEmail, setHcpEmail] = React.useState('');
  const [hcpPhoneNumber, setHcpPhoneNumber] = React.useState('');
  const [consentCheckBox, setconsentCheckBox] = React.useState(false);
  const [showClosure, setShowClosure] = React.useState(false);
  let history = useHistory();

  //#endregion
  //#region formStates
  const forwardStates = {
    Id: { get: hcpID, set: setHcpID },
    Name: { get: hcpName, set: setHcpName },
    Email: { get: hcpEmail, set: setHcpEmail },
    Number: { get: hcpPhoneNumber, set: setHcpPhoneNumber },
    CheckBox: { get: consentCheckBox, set: setconsentCheckBox },
  };
  //#endregion

  const handleClick = async (type) => {
    switch (type) {
      case 'submit':
        history.push('SPForm');
        break;
      case 'logout':
        await SetSessionInformationHCP(null);
        window.location.replace('/SelectAffiliate');
        break;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  return (
    <div>
      <h3>Home</h3>
      <Grid container justify="center" alignContent="center">
        <Button
          size="large"
          className={styles.ButtonAlignCenterNoMaxWidth}
          variant="contained"
          onClick={() => handleClick('logout')}
        >
          LogOut from the system
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleClick('submit')}
          className={`${styles.ButtonAlignCenterNoMaxWidth} ${styles.ColorbackgroundPrimary}`}
        >
          Submit new Request
        </Button>
      </Grid>
    </div>
  );
};

export default HCPHome;
