import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import uniqid from 'uniqid';

import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
  Paper,
  Hidden,
} from '@material-ui/core';
import styles from '../../../css/baseStyle.css';

const formInputBlock = (props) => {
  // const [id] = React.useState(_uniqueId('prefix-'));
  // const [value, setValue] = React.useState(props.defaultValue ?? '');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState();
  const [stateValue, setStateValue] = React.useState(props.value ?? props.defaultValue);

  useEffect(()=>{
    setStateValue(props.value)
  },[props.value]);

  // const stateValue = props.value ?? props.defaultValue; // ?? value;
  const stateSetValue = (value) => {
    if (props.isRequired == true && (value == null || value == '')) {
      setError(true);
      setHelperText(`${props.label} is required`);
    } else if (props.isRequired == true && value != null) {
      setHelperText('');
      setError(false);
    }
    // props.onChangeparams != null
    //   ? props.setValue(value, props.onChangeparams)
    //   : props.setValue(value);
    if (props.onChangeparams != null) {
      props?.setValue(value, props.onChangeparams);
    } else {
      props?.setValue(value);
    }
    setStateValue(value)
  }; // ?? setValue;

  const generateDeviceControl = (props) => {
    if (props.deviceControl == null) {
      return (
        <TextField
          error={error}
          disabled={props.isDisabled ?? false}
          required={props.isRequired ?? false}
          id={props.id ?? uniqid()}
          label={props.label ?? 'No Label'}
          value={stateValue}
          multiline={props.multiline ?? false}
          maxRows={props.rowsMax ?? 3}
          onChange={(event) => stateSetValue(event.target.value)}
          defaultValue={props.defaultValue}
          variant="outlined"
          className={styles.GlobalFormControlInputDevice}
          type={props.type ?? 'text'}
          size="small"
          helperText={
            props.helperText == null || props.helperText == ''
              ? helperText
              : props.helperText
          }
          InputProps={{ endAdornment: props.iconEnd ?? null }}
        />
      );
    } else {
      return props.deviceControl;
    }
  };
  const generateControl = (props) => {
    if (props.control == null) {
      return (
        <TextField
          error={error}
          disabled={props.isDisabled ?? false}
          required={props.isRequired ?? false}
          value={stateValue}
          onChange={(event) => stateSetValue(event.target.value)}
          id={props.id ?? ''}
          multiline={props.multiline ?? false}
          maxRows={props.rowsMax ?? 3}
          defaultValue={props.defaultValue}
          className={styles.GlobalFormControlInput}
          variant="outlined"
          helperText={
            props.helperText == null || props.helperText == ''
              ? helperText
              : props.helperText
          }
          InputProps={{ endAdornment: props.iconEnd ?? null }}
          size="small"
          type={props.type ?? 'text'}
        />
      );
    } else {
      return props.control;
    }
  };

  return (
    <div>
      {props.controlType == 'DeviceOnly' ? (
        generateDeviceControl(props)
      ) : (
        <>
          <Hidden smDown>
            <FormControlLabel
              id={props.id ?? ''}
              control={generateControl(props)}
              label={`${props.label ?? 'No Label'}${
                props.isRequired ? '*' : ''
              }`}
              labelPlacement="start"
              classes={{
                root: styles.GlobalFormControlAlignLeft,
                label: styles.GlobalFormControlLabel,
              }}
            />
          </Hidden>
          <Hidden mdUp>{generateDeviceControl(props)}</Hidden>
        </>
      )}
    </div>
  );
};

export default formInputBlock;
