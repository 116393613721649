import React, { useState } from 'react';

import { Typography, Card, CardContent } from '@material-ui/core';
import styles from '../../../css/baseStyle';

const HighlightCard = (props) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleClick = (e)=>{
    console.log("cardEEEE", e);
    props.onClick(e)
  }
  return (
    <Card
    elevation={6}
      className={styles.dashboardCardContainer}
      style={{ border: isHovered ? "2px solid"+ props.bgColor : "", cursor:'pointer'}}
      onMouseEnter={()=>{setIsHovered(true)}}
      onMouseLeave={()=>{setIsHovered(false)}}
    >
      <CardContent
        onClick={() => handleClick(props.Heading)}
        className={styles.dashboardCardContent}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{
            color: props.Color,
            fontWeight: 500,
          }}
        >
          {props.Heading}
        </Typography>
        <Typography
          variant={props.Count.length < 3 ? "h5" : "h5"}
          style={{
            fontWeight: 500,
          }}
        >
          {props.Count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default HighlightCard;
