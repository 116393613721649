export const dashboardCardlists = [
  {
    id: 0,
    heading: "All Submissions",
    applicationCount:0,
    affiliateType: ["AU", "IT", "ES"],
    color: "#0078a3",
    backgroundColor:"#0078a3",
  },
  {
    id: 1,
    heading: "Draft",
    applicationCount:0,
    affiliateType: ["ES", "AU", "IT"],
    color: "#d52b1e",
    backgroundColor: "#d52b1e",
  },
  {
    id: 7,
    heading: "Pending Approval",
    applicationCount:0,
    affiliateType: ["IT"],
    color: "#d52b1e",
    backgroundColor: "#d52b1e",
  },
  {
    id: 2,
    heading: "Submitted",
    applicationCount:0,
    affiliateType: ["ES", "AU"],
    color: "#45BA7F",
    backgroundColor: "#45BA7F",
  },
  {
    id: 3,
    heading: "Closed",
    applicationCount:0,
    affiliateType: ["AU"],
    color: "#45BA7F",
    backgroundColor: "#45BA7F",
  },
  {
    id: 4,
    heading: "Rejected",
    applicationCount:0,
    affiliateType: ["AU", "IT"],
    color: "#d52b1e",
    backgroundColor: "#d52b1e",
  },
  {
    id: 8,
    heading: "SAP Orders",
    applicationCount:0,
    affiliateType: ["IT"],
    color: "#d52b1e",
    backgroundColor: "#d52b1e",
  },
  {
    id: 5,
    heading: "Order Sent to SAP",
    applicationCount:0,
    affiliateType: ["ES"],
    color: "#FFA500",
    backgroundColor: "#FFA500",
  },
  {
    id: 6,
    heading: "Delivered",
    applicationCount:0,
    affiliateType: ["ES"],
    color: "#4BB543",
    backgroundColor: "#4BB543",
  },
  {
    id: 6,
    heading: "Cancelled",
    applicationCount:0,
    affiliateType: ["IT"],
    color: "#09467c",
    backgroundColor: "#09467c",
  },
];

export const searchLists = [
  {
    id: 1,
    orderid: "Order ID",
    affiliateType: ["AU", "ES"],
  },
  {
    id: 2,
    status: "Status",
    affiliateType: ["AU", "ES"],
  },
  {
    id: 3,
    requesterid: "Requester ID",
    affiliateType: ["AU", "ES"],
  },
  {
    id: 4,
    requestername: "Requester Name",
    affiliateType: ["AU", "ES"],
  },
  {
    id: 5,
    startdate: "Start Date",
    affiliateType: ["AU", "ES"],
  },
  {
    id: 6,
    enddate: "End Date",
    affiliateType: ["AU", "ES"],
  },
];

