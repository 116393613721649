import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom"
import SpeedDial from "@material-ui/lab/SpeedDial";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  MenuItem,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  FormControlLabel
} from "@material-ui/core";

import FilterListIcon from "@material-ui/icons/FilterList";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import styles from "../../../css/baseStyle";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import HighlightCard from "../../common/components/highlightCard";
import SubmissionList from "../components/submissionList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import slice, { GetSessionInformation } from "../../../Store/Slices/SessionSlice";
import FormInputBlock from "../../common/components/formInputBlock";
import FormDatePicker from "../../common/components/FormDatePicker";
import FormSelectBlock from "../../common/components/formSelectBlock";
import CustomFullLoader from "../../../helpers/CustomFullLoader"
import moment from "moment"
import {
  dashboardCardlists,
  searchLists,
} from "../../../helpers/dashboardCardLists";
import axios from 'axios'
import { SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSnackbar } from "notistack";

const dashboard = () => {
  let history = useHistory();
  const [showCards, setShowCards] = useState(true);
  const [affiliateCode, setAffilaiteCode] = useState("NaN");
  const [countryName, setCountryName] = useState("");
  const _affiliateCode =  window.location.pathname.split("/")[1]
  const [HCPID, setHCPID] = useState('')
  const [datas, setDatas] = useState([])
  const [filterDatas, setFilterDatas] = useState([])
  const [filterCards, setFilterCards] = useState([])
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const [orderId, setOrderId] = useState("");
  const [status, setStatus] = useState('')
  const [hcpId, setHcpId] = useState('')
  const [hcpName, setHcpName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const forwardStates = {
    search: {
      orderId: {
        get: orderId,
        set: setOrderId,
      },
      Date: {
        start: {
          get: startDate,
          set: setStartDate,
        },
        end: {
          get: endDate,
          set: setEndDate,
        },
      },
      Status: {
        get: status,
        set: setStatus,
      },
      HCP: {
        ID: {
          get: hcpId,
          set: setHcpId,
        },
        Name: {
          get: hcpName,
          set: setHcpName,
        },
      },
    },
  };
  
  const getStatusMenuItems = () => {
     if (_affiliateCode == "ES") {
      let list = ["Submitted", "Draft", "Order-sent-to-sap", "Delivered"];
      return list.map((unit) => (
        <MenuItem key={unit} value={unit}>
          {unit}
        </MenuItem>
      ));
    } 
  };
  

  const handleVisibility = () => {
    setHidden((prevHidden) => !prevHidden);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToSpForm = ()=>{
    history.push("/ES/spForm")
  }

  const cardlists = dashboardCardlists;
  const getAffilaiteFromSession = () => {
    GetSessionInformation().then((data) => {
      let affiliate = "";
      const sessionData = JSON.parse(data.payload);
      // console.log(sessionData);
      setCountryName(sessionData._data.DisplayName);
      affiliate = sessionData._data.CountryCode;
      setAffilaiteCode(affiliate);
    });
  };

  useEffect(() => {
    getAffilaiteFromSession();
  }, [affiliateCode]);

  useEffect(()=>{
    GetSessionInformation().then((data) => {
      const sessionData = JSON.parse(data.payload);
      // console.log("sessionData", sessionData._data);
      setHCPID(sessionData._data.CRMID)
      LoadAffiliateInfo(_affiliateCode, sessionData._data)
      })
  },[])

  const LoadAffiliateInfo = (affiliateCode, _sessionData) => {
    axios
      .get("/api/admin/get", {
        headers: {
          URL: "adminService/User/affiliate/" + affiliateCode,
        },
      })
      .then((data) => {
        // console.log(data.data, _sessionData);
        let affiliate = data.data;
        // console.log("affiliate ", data);
        LoadHCPProducts(affiliate.affiliate_id, _sessionData.CRMID);
        // GetAnnouncement(affiliate.affiliate_id);
      })
      .catch((err) =>
        console.log("----LoadAffiliateInfo-------------err------------", err)
      );
  };

  const LoadHCPProducts = (affiliateId, hcpId) => {
    // const data = JSON.parse(localStorage.getItem("previousOrder"));
    // if(data?.length > 0){
    //   console.log("didn;t do a call as data was present");
    //   setDatas(data?.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
    //   setFilterDatas(data)
    //   setIsLoading(false)

    //   const statusCount = data?.reduce((acc, obj) => { 
    //     const status = obj.status; 
    //     if (acc[status]) { 
    //       acc[status]++; 
    //     } else { 
    //       acc[status] = 1; 
    //     } return acc; 
    //   }, {});
    //   console.log("====>", statusCount);

    //   const filterCards = cardlists
    //   .map((item) => item)
    //   .filter((item) => item.affiliateType.includes(_affiliateCode));

    //   filterCards.forEach(app => { 
    //     if (statusCount[app.heading.trim()] !== undefined) { 
    //       app.applicationCount = statusCount[app.heading];
    //     } else if (app.id === 0){
    //       app.applicationCount = data?.length;
    //     }});

    //     setFilterCards(filterCards);
        
    // }
    // else {
      // console.log("did a call as data was not present");
      
      axios
        .get("/api/order/get", {
          headers: {
            URL: "api/hcp/GetPreviousOrder/37/"+hcpId,
          },
        })
        .then((data)=> {
          localStorage.setItem("previousOrder", JSON.stringify(data?.data));
          setDatas(data?.data.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
          setFilterDatas(data?.data)
          setIsLoading(false)
  
          const statusCount = data?.data.reduce((acc, obj) => { 
            const status = obj.status; 
            if (acc[status]) { 
              acc[status]++; 
            } else { 
              acc[status] = 1; 
            } return acc; 
          }, {});
          // console.log("====>", statusCount);
  
          const filterCards = cardlists
          .map((item) => item)
          .filter((item) => item.affiliateType.includes(_affiliateCode));
  
          filterCards.forEach(app => {
            app.applicationCount = 0; 
            if (statusCount[app.heading.trim()] !== undefined) { 
              app.applicationCount = statusCount[app.heading];
            } else if (app.id === 0){
              app.applicationCount = data?.data?.length;
            }});
    
            setFilterCards(filterCards);
            // console.log("=====>", filterCards);
          }).catch((err)=>{
            enqueueSnackbar(
              "Error while fetching data, wait for a while",
              {
                variant: "warning",
              }
            );

            setTimeout(() => {
              window.location.reload();
            }, 10000);
          });
    // }
    }

  // useEffect(()=>{
  //   const filterCards = cardlists
  //   .map((item) => item)
  //   .filter((item) => item.affiliateType.includes(_affiliateCode));
  //   setFilterCards(filterCards)
  // },[_affiliateCode]);

  useEffect(() => {
    // console.log("filterCards", filterCards);
  }, [filterCards])


  const handleFilterForm = (heading)=>{
    if(heading.toLowerCase() === "all submissions"){
      setFilterDatas(datas?.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
    }else{
      setFilterDatas(datas.filter((item)=> item?.status?.toLowerCase().trim() === heading.toLowerCase().trim()).sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
    }
  }

  

  // const handleOnSubmit = (e) => {
  //   console.log("SEARCH EVENT--------------", e)
  //   if(status === "All Submissions" || status=== ""){
  //     setFilterDatas(datas.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
  //   }
  //   else{
  //     setFilterDatas(datas?.filter(
  //       (data) =>
  //         data.order_id === parseInt(orderId) ||
  //         data.hcp_id === hcpId ||
  //         data.hcp_name === hcpName ||
  //         data.status === status
  //     ).sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)));
  //   }
  //   setOrderId("")
  //   setStatus("")
  //   setHcpId("")
  //   setHcpName("")
  //   setStartDate("")
  //   setEndDate("")
  // };


  const filterFormData = () => {
    let tempData = datas;
    if (
      forwardStates.search.orderId.get != null &&
      forwardStates.search.orderId.get != ""
    ) {
      tempData = tempData.filter((row) => {
        return (
          row.order_id != null &&
          row.order_id.toString().toUpperCase().includes(
            forwardStates.search.orderId.get.toUpperCase()
          )
        );
      });
    }
    if (
      forwardStates.search.Status.get != null &&
      forwardStates.search.Status.get.length > 0 &&
      forwardStates.search.Status.get != "" &&
      forwardStates.search.Status.get != "All"
    ) {
      tempData = tempData.filter((row) => {
        return (
          row.status.toUpperCase() ==
          forwardStates.search.Status.get.toUpperCase()
        );
      });
    }

    if (
      forwardStates.search.HCP.ID.get != null &&
      forwardStates.search.HCP.ID.get != ""
    ) {
      tempData = tempData.filter((row) => {
        return (
          row.hcp_id != null &&
          row.hcp_id.toUpperCase().includes(
            forwardStates.search.HCP.ID.get.toUpperCase()
          )
        );
      });
    }
    if (
      forwardStates.search.HCP.Name.get != null &&
      forwardStates.search.HCP.Name.get != ""
    ) {
      tempData = tempData.filter((row) => {
        return (
          row.hcp_name != null &&
          row.hcp_name.toUpperCase().includes(
            forwardStates.search.HCP.Name.get.toUpperCase()
          )
        );
      });
    }
    if (
      forwardStates.search.Date.start.get != null &&
      forwardStates.search.Date.start.get != ""
    ) {
      let startDate = `${forwardStates.search.Date.start.get.getFullYear()}-${
        (forwardStates.search.Date.start.get.getMonth() > 8 ? "" : "0") +
        (forwardStates.search.Date.start.get.getMonth() + 1)
      }-${
        (forwardStates.search.Date.start.get.getDate() > 9 ? "" : "0") +
        forwardStates.search.Date.start.get.getDate()
      }`;

      tempData = tempData.filter((row) => {
        return row.created_on != null && row.created_on >= startDate;
      });
    }
    if (
      forwardStates.search.Date.end.get != null &&
      forwardStates.search.Date.end.get != ""
    ) {
      let endDate = `${forwardStates.search.Date.end.get.getFullYear()}-${
        (forwardStates.search.Date.end.get.getMonth() > 8 ? "" : "0") +
        (forwardStates.search.Date.end.get.getMonth() + 1)
      }-${
        (forwardStates.search.Date.end.get.getDate() > 9 ? "" : "0") +
        forwardStates.search.Date.end.get.getDate()
      }`;
      let x = new Date(endDate);
      let y = x.toISOString().split("T");
      let endString = y[0].concat("T23:59:59.999");
      let compareDate = new Date(endString);
      tempData = tempData.filter((row) => {
        // console.log(row.created_on <= compareDate, row.created_on, compareDate);
        return (
          row.created_on != null && new Date(row.created_on) <= compareDate
        );
      });
    }
    // console.log("Filtered data", tempData);
    setFilterDatas(tempData);
  };


const resetFormData =()=>{
    setFilterDatas(datas?.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on)))
    setOrderId("")
    setStatus("")
    setHcpId("")
    setHcpName("")
    setStartDate("")
    setEndDate("")
    forwardStates.search.Date.start.set(null);
    forwardStates.search.Date.end.set(null);
}

const fetchFormData = ()=>{
  const key = enqueueSnackbar(
    "Consolidating Data",
    {
      variant: "info",
    }
  );
  // MAKING API CALL 
  
  let model = {
    device: ["0"],
    online: ["0"],
    offline: ["0"],
    hcp: ["0"],
    needlePen: ["0"],
  };
  filterDatas.forEach((item) => {
    let tempItem = item;
    if (tempItem.Type == "Main") {
      model.online.push(tempItem.ID.toString());
    } else if (tempItem.Type == "withConsent") {
      model.offline.push(tempItem.ID.toString());
    } else if (tempItem.Type == "HCP") {
      model.hcp.push(tempItem.ID.toString());
    } else if (tempItem.Type == "Devices") {
      model.device.push(tempItem.ID.toString());
    } else if (tempItem.Type == "Needles") {
      model.needlePen.push(tempItem.ID.toString());
    }
  });
      let dataExport = [];
      
      if (filterDatas?.length > 0) {
        // console.log("EXECELLLLLLLL-------------",filterDatas);
        for (let index = 0; index < filterDatas?.length; index++) {
          let tempModel = {};
          const element = filterDatas[index];
          // console.log("Element is fox", element);
          tempModel[
            "Order ID"
          ] = `HCP-SP-${element?.order_id}`;
          // tempModel["Requester ID"] = " ";
          // tempModel["Requester Name"] = " ";
          // tempModel["Requester Email"] = " ";
          tempModel["HCP ID"] = element.hcp_id;
          tempModel["HCP Name"] = element.hcp_name;
          tempModel["HCP Email"] = element.email;
          tempModel["Affiliate Id"] = element.affiliate_id === 7 ? "Spain":element.affiliate_id;
          tempModel["Address"] = element.hcp_address;
          tempModel["Attachments"] = element.attachments;
          tempModel["Status"] = element.status ?? "Submitted";
          tempModel["Created On"] =  moment(element.created_on).format("DD-MM-YYYY HH:mm:ss");
          tempModel["Shipped On"] =
            (element.status ?? "Submitted").toUpperCase() ==
            "Shipped".toUpperCase()
              ?  moment(element.updated_on).format("DD-MM-YYYY HH:mm:ss")
              : "";
          tempModel["Delivery Instruction"] = element?.delivery_instruction;
          tempModel["Created by User id"] = element?.created_by_user_id;
          tempModel["Updated by User id"] = element?.updated_by_user_id;
          tempModel["Specialty"] = element?.Specialty;
          tempModel["Product Name"] = element?.product_array?.TA474158WNAF1_7?.product_name;
          tempModel["Packsize"] = element?.product_array?.TA474158WNAF1_7?.pack_size;
          tempModel["Quantity"] = element?.order_data[0]?.Quantity;
          tempModel["HCP Quota"] = 0;
          // tempModel["Delivered Products"] = 0;
          // tempModel["In-Transit Products"] = 0;
          dataExport.push(tempModel);
        }
      }
      // let dataExport2 = [...dataExport,...data.data.hcpForm]
      // console.log(dataExport);
        let url = "/api/admin/excel";
        if(_affiliateCode === "ES"){
          url = "/api/admin/excel?type=dashboard"
        }
      axios
        .post(
          url,
          dataExport.sort((a, b) =>
            a.created_on > b.created_on
              ? -1
              : a.created_on == b.created_on && a.ID > b.ID
              ? -1
              : 1
          ),
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Sample Order Forms.xlsx`)
          // link.setAttribute("download", `dashboard_${new Date()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          closeSnackbar(key);
      enqueueSnackbar(
        "File Genearted",
        {
          variant: "success",
        }
      );
        })
        .catch((error) => console.log(error));
}

  if(isLoading) return <CustomFullLoader />
  return (
    <div>
      <div className={styles.containerPaper}>
        <h3 className={styles.headerBottomPadding}>Dashboard</h3>
        <Accordion
          expanded={showCards}
          onChange={() => setShowCards(!showCards)}
          style={{ width: "100%", marginBottom: "0.8rem" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Status Cards</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.ParentCardsContainer}>
              {filterCards.length === 0 && (
                 <h6>Looks like no card available for {countryName} </h6>
              )}
              {filterCards &&
                filterCards?.map((card, index) => {
                  if(card.applicationCount !==0){
                    return (
                      <HighlightCard
                        key={index}
                        Color={card?.color ? card?.color : "black"}
                        bgColor={card?.backgroundColor ? card?.backgroundColor : "black"}
                        Heading={card?.heading}
                        Count={card?.applicationCount}
                        onClick={(heading) => handleFilterForm(heading)}
                      />
                    );
                  }
                })}
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Search section  */}
        <Accordion
          expanded={!showCards}
          onChange={() => setShowCards(!showCards)}
          style={{ width: "100%", marginBottom: "1rem" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <h4>Search</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className={styles.formTemplateMarginBottom}>
              
            <Grid item xs={12} md={6}>
                      <FormInputBlock
                        label="Order Id"
                        value={forwardStates?.search?.orderId?.get}
                        setValue={forwardStates?.search?.orderId?.set}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormSelectBlock
                        label="Status"
                        value={forwardStates.search.Status.get}
                        setValue={forwardStates.search.Status.set}
                        MenuItems={getStatusMenuItems()}
                        // multiple={true}
                        id="Status"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                          <FormInputBlock
                            label="HCP id"
                            value={forwardStates.search.HCP.ID.get}
                            setValue={forwardStates.search.HCP.ID.set}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormInputBlock
                            label="HCP Name"
                            value={forwardStates.search.HCP.Name.get}
                            setValue={forwardStates.search.HCP.Name.set}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                      <FormDatePicker
                        id="startDate"
                        format={
                            "MM/dd/yyyy"
                        }
                        label="Start Date"
                        value={forwardStates?.search?.Date?.start?.get}
                        setValue={forwardStates?.search?.Date?.start?.set}
                      />
                    </Grid>
                        <Grid item xs={12} md={6}>
                        <FormDatePicker
                        id="endDate"
                        format={
                            "MM/dd/yyyy"
                        }
                        label="End Date"
                        value={forwardStates.search.Date.end.get}
                        setValue={forwardStates.search.Date.end.set}
                        helperText="Invalid Date format"
                      />
                    </Grid>
              {/* <form className={styles.commonGrid}>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>Order Id</label>
                  <input
                    className={styles.commonInputer}
                    type="text"
                    placeholder=""
                    name="orderId"
                    value={orderId}
                    onChange={(e)=>handleChange(e, "orderId")}
                  />
                </div>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>Status</label>
                  <select className={styles.commonInputer} name="status" id="status" value={status} onChange={(e)=>handleChange(e, "status")}>
                  <option className={styles.commonInputer} selected></option>
                    {filterCards.map((item) => {
                      return (
                        <option name="status"  className={styles.commonInputer}>
                          {item?.heading}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>HCP Id</label>
                  <input
                    className={styles.commonInputer}
                    type="text"
                    placeholder=""
                    name="hcpId"
                    value={hcpId}
                    onChange={(e)=>handleChange(e, "hcpId")}
                  />
                </div>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>HCP Name</label>
                  <input
                    className={styles.commonInputer}
                    type="text"
                    placeholder=""
                    name="hcpName"
                    value={hcpName}
                    onChange={(e)=>handleChange(e, "hcpName")}
                  />
                </div>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>Start Date</label>
                  <input
                    className={styles.commonInputer}
                    id="date" type="date" 
                    name="startDate"
                    value={startDate}
                    onChange={(e)=>handleChange(e, "startDate")}
                  />
                </div>
                <div className={styles.displayFlex}>
                  <label className={styles.commonLabel}>End Date</label>
                  <input
                    className={styles.commonInputer}
                    id="date" type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e)=>handleChange(e, "endDate")} 
                  />
                </div>
              </form> */}
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            
            <Button
              size="small"
              className={styles.ButtonAlignRight}
              variant="contained"
              onClick={fetchFormData}
            >
              <ImportExportIcon />
              Export to Excel
            </Button>

            <Button
              size="small"
              className={styles.ButtonAlignRight}
              variant="contained"
              onClick={filterFormData}
            >
              <FilterListIcon />
              Search
            </Button>
            <Button
              size="small"
              className={styles.ButtonAlignRight}
              variant="contained"
              onClick={resetFormData}
            >
              Reset filter
            </Button>
          </AccordionActions>
        </Accordion>
        {/* Search section  */}
      </div>
      <SubmissionList
        affilaite_Code={_affiliateCode}
        hasEditAllacces={null}
        Orders={filterDatas.sort((a,b)=> new Date(b?.updated_on) - new Date(a?.updated_on))}
        modulesMap={null}
      />
      <SpeedDial
          ariaLabel="Raise new Request"
          className={styles.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
        
            <SpeedDialAction
              key={"Sample Order"}
              icon={<HowToRegIcon />}
              tooltipTitle={"Sample Order"}
              tooltipOpen
              classes={{
                tooltipPlacementLeft: styles.toolTipNoWrap,
              }}
              onClick={navigateToSpForm}
            />
          {/* {console.log(sessionCurrentUser, forms.length, forms)} */}
        </SpeedDial>
    </div>
  );
};

export default dashboard;
