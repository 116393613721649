import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = [
  {
    userId: 1,
    countryId: 1,
    countryCode: 'AU',
    lillyId: 'c023456',
    userName: 'ASd asd',
    userEmail: 'test@test.com',
  },
];
const SessionSlice = createSlice({
  name: 'Session',
  initialState,
  reducers: {
    LoginSuccess(state, action) {
      // console.log(
      //   '------------------------------------------STAte triggered',
      //   state
      // );
      // console.log(action.payload);
      state.push(action.payload);
    },
  },
});

function GetSessionCookie() {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let _cookies = cookies[i].split('=');
    // console.log('------------GetSessionCookie----------', _cookies[0].trim());
    if (_cookies[0].trim() == 'sessionUser') {
      let signValue = _cookies[1].trim();
      return signValue;
    }
  }
}
export async function SetSessionInformation(data) {
  let body = {
    _affiliateRoles: data.affiliateRoles,
    _lillyId: data.lillyId,
    _userName: data.name,
    _userEmail: data.email,
  };
  let status = await axios.post('/api/clientsupport/encrypt/sessionUser', body);
  return status.data;
}
export async function GetSessionInformation() {
  let seesionSign = GetSessionCookie();
  let body = {
    key: 'UserInfo',
    value: seesionSign,
  };
  // console.log('------------GetSessionInformation----------', body);
  let data = await axios.post('/api/clientsupport/decrypt', body);
  return data.data;
}

export default function SessionReducer(state = initialState, action) {
  console.log('------------------------Tridderfed dispatch to sessionreduecr');

  // state.push( {
  //     userId : action.payload.userId,
  //     countryId : action.payload.countryId,
  //     countryCode : action.payload.countryCode,
  //     lillyId : action.payload.lillyId
  // });

  if (action != undefined) {
    switch (action.type) {
      case 'Login': {
        // console.log('------------------------------------state', state);
        // console.log(
        //   '------------------------------------initialState',
        //   initialState
        // );
        // console.log('------------------------------------action', action);
        let test = [
          ...state,
          {
            userId: action.payload.userId,
            countryId: action.payload.countryId,
            countryCode: action.payload.countryCode,
            lillyId: action.payload.lillyId,
            userName: action.payload.userName,
            userEmail: action.payload.userEmail,
          },
        ];

        // console.log('-----------------------------test', test);
        localStorage.setItem('userId', action.payload.userId);
        localStorage.setItem('countryId', action.payload.countryId);
        localStorage.setItem('countryCode', action.payload.countryCode);
        localStorage.setItem('lillyId', action.payload.lillyId);
        localStorage.setItem('userName', action.payload.userName);
        localStorage.setItem('userEmail', action.payload.userEmail);

        return [
          ...state,
          {
            userId: action.payload.userId,
            countryId: action.payload.countryId,
            countryCode: action.payload.countryCode,
            lillyId: action.payload.lillyId,
            userName: action.payload.userName,
            userEmail: action.payload.userEmail,
          },
        ];
      }
      default:
        return state;
    }
  } else {
    return state;
  }
}
export async function SetActiveSession(data, currentUser) {
  let body = {
    _affiliateRoles: data.affiliateRoles,
    _lillyId: data.lillyId,
    _userName: data.name,
    _userEmail: data.email,
    _currentUser: currentUser,
  };
  let status = await axios.post('/api/clientsupport/encrypt/sessionUser', body);
  return status.data;
}

export async function SetSessionInformationHCP(data) {
  let body = {
    _data: data,
  };
  let status = await axios.post('/api/clientsupport/encrypt/sessionUser', body);
  return status.data;
}

export const { LoginSuccess } = SessionSlice.actions;
//export default SessionSlice.reducer
