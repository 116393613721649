import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline, BrandingWatermark } from '@material-ui/icons';
import styles from '../../../css/baseStyle.css';
import { useHistory } from 'react-router-dom';

import {
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  CardContent,
  Paper,
  Checkbox,
  TableCell,
  TableContainer,
  Table,
  Slide,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
  Select,
  Button,
  TextField,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const OnClosureModal = React.forwardRef((props, ref) => {
  let history = useHistory();
  //   const [open, setOpen] = React.useState(false);
  const open = props.showClosure;
  const setOpen = props.setShowClosure;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (actionType) => {
    
    if (actionType == 'yes' && props.enableRedirect) {
      // history.replace(props.redirectTo);
      history.push("/ES/dashboard")
    }else{
      // history.push("/ES/dashboard")
      setOpen(false)
    }
    setOpen(false);
  };
  //   if (actionType == 'yes' && (props.enableRedirect ?? true)) {
  //     history.replace(props.redirectTo);
  //   }
  //   setOpen(false);
  // };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      //   aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.message ?? 'Are you sure you want to go back to dashboard?'}
      </DialogTitle>
      <DialogActions>
        {props.message == null ? (
          <Button onClick={() => handleClose('no')} color="primary">
            No
          </Button>
        ) : (
          ''
        )}
        <Button onClick={() => handleClose('yes')} color="primary">
          {props.message == null ? 'Yes' : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default OnClosureModal;
