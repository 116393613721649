import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import styles from '../../../css/baseStyle.css';
import SalesRep from './SalesRep';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PieChart } from 'react-minimal-pie-chart';
import {
    Card,
    CardContent,
    Paper,
    Checkbox,
    FormHelperText,
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    RadioGroup,
    Radio,
    TableBody,
    MenuItem,
    Select,
    Button,
    TextField,
    Grid,
    InputLabel,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    baseModal: {
        position: 'absolute',
        top: '20%',
        left: '10%',
        width: '80%',
        height: '60%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #D52B1E',
        borderRadius: '30px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    labelTextContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    labelCheckBoxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    labelText: {
        textAlign: 'left !important',
        font: 'normal normal normal 16px/21px Roboto',
        color: '#333232',
        opacity: '1',
    },
    textFieldMaxWidth: {
        width: '100% !important',
    },
    paperMaxWidth: {
        width: '100% !important',
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
    },
    containerMargin: {
        marginBottom: '5px',
    },
    containerRightPadding: {
        paddingRight: '15px',
        paddingBottom: '15px',
    },
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#D52B1E',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const customColumnStyle = { width: 0.3, minWidth: 50 };

const AddPackSize = React.forwardRef((props, ref) => {
    const classes = useStyles();

    const [quantity, setQuantity] = React.useState(0);
    const [additionalCheckBoxError, setAdditionalCheckBoxError] = React.useState(false);
    const [quotaCheckError, setQuotaCheckError] = React.useState(false);

    const _affiliateCode = window.location.pathname.split("/")[1];

    const handleSubmit = () => {
        console.log(props);
        if (quantity < 1) {
            setAdditionalCheckBoxError(true);
        } else if (props.Quotas.Total - (props.Quotas.Delivered + props.Quotas.inTransit) < quantity){
            setQuotaCheckError(true);
        }
        else {
            setAdditionalCheckBoxError(false);
            props.quantitySubmitClick(props.brandID, props.productId, quantity);
        }
    };

    const getPieChart = (data, type) => {
        console.log('getPieChart(data, type)', data, type);
        const defaultLabelStyle = {
            fontSize: '.75rem',
            fontFamily: 'sans-serif',
        };
        if (data != null && data.Total != 0) {
            console.log("getPietchart")
            const available = data.Total - (data.Delivered + data.inTransit);
            const delivered = data.Delivered;
            const inTransit = data.inTransit;

            let displayData = [];
            if (delivered != 0)
                displayData.push({
                    title: 'Delivered',
                    value: delivered,
                    // value: `${data.Delivered}`,
                    color: '#46F0F7',
                });

            if (inTransit != 0)
                displayData.push({
                    title: 'inTransit',
                    value: inTransit,
                    // value: `${data.Delivered}`,
                    color: '#DB144F',
                });
            // console.log(props.isDevices, data.Total);
            if (data.Total != 0 && data.Total != null && available > 0)
                displayData.push({
                    title: 'Available',
                    value: available,
                    // value: `${data.Total - (data.Delivered + data.inTransit)}`,
                    color: '#45BA7F',
                });

            return (
                <>
                    {displayData.length > 0 ? (
                        <Paper elevation={2} className={styles.paperForPieChart}>
                            <p className={styles.CenterText}>{type}</p>
                            <PieChart
                                lineWidth={75}
                                label={({ dataEntry }) => dataEntry.value}
                                viewBoxSize={[110, 110]}
                                labelPosition={50}
                                labelStyle={{
                                    ...defaultLabelStyle,
                                }}
                                data={displayData}
                            />
                            {quotaCheckError == true ? "Quota Limit Reached" : ''}
                        </Paper>
                    ) : (
                        ''
                    )}
                </>
            );
        } else return;
    };

    const diplayQuotaOrConfiramtion = (props) => {
        console.log('displayQuota',props)
        const defaultLabelStyle = {
            fontSize: '.75rem',
            fontFamily: 'sans-serif',
        };
            return (
                <div className={styles.displayFlex}>
                    {getPieChart(props.Quotas, 'HCP')}
                </div>
            );
    };

    const displayColdChainInform = (props) => {
        console.log(props.Product);
        return (
            <>
                {props.Product.ColdChain == 'REFRIGERATED' ? (
                    <Grid>
                        <Alert severity="warning" color="info" style={{ backgroundColor: '#DFF7FA' }}>
                            <AlertTitle>YOU ARE ORDERING A COLD CHAIN PRODUCT THAT REQUIRES REFRIGERATION.</AlertTitle>
                            If you have any special delivery instructions, please{' '}
                            <Link to="addAddress">click here</Link> and a Lilly representative will assist you in
                            completing your order.
                        </Alert>
                    </Grid>
                ) : (
                    ''
                )}
                {props.productGroup == '404189' ? (
                    <Grid>
                        <Alert severity="info" color="success" style={{ backgroundColor: '#e1f2e2' }}>
                            <AlertTitle>
                                WOULD YOU LIKE TO BE CONTACTED BY ATLANTIS HEALTHCARE WITH INFORMATION ABOUT THE TALTZ
                                PATIENT SUPPORT PROGRAM?
                            </AlertTitle>
                            Lilly will provide your contact details (email/phone) to Atlantis Healthcare:
                            <FormControl component="fieldset" fullWidth={true}>
                                <RadioGroup
                                    row
                                    aria-label="atlantisConfirmation"
                                    name="atlantisConfirmation"
                                    value={props.additionalCheckbox.get}
                                    onChange={(event) => props.additionalCheckbox.set(event.target.value)}>
                                    <FormControlLabel value="true" control={<Radio color="#224f25" />} label="Yes" />
                                    <FormControlLabel value="false" control={<Radio color="#224f25" />} label="No" />
                                </RadioGroup>
                                {additionalCheckBoxError ? (
                                    <FormHelperText error={true}>*Please select either Yes or No</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </FormControl>
                        </Alert>
                    </Grid>
                ) : (
                    ''
                )}
                {props.productGroup == '100000' ? (
                    <Grid>
                        <Alert severity="info" color="warning" style={{ backgroundColor: '#ffefd8' }}>
                            {/* <AlertTitle>YOU ARE ORDERING A COLD CHAIN PRODUCT THAT REQUIRES REFRIGERATION.</AlertTitle> */}
                            <div>
                                The Verzenio<sup>TM</sup> "Patient Booklet" and "Tips to Help You Manage Side Effects"
                                leaflet are also available as downloadable PDF.
                            </div>
                            <div>
                                This material is for patients who have been prescribed Verzenio<sup>TM</sup> only. It is
                                not intended for general distribution.
                            </div>
                            <div>Download now by clicking on the links below:</div>
                            <div>
                                <div>
                                    <a
                                        href="https://image.mc.lilly.com/lib/fe9112737665057472/m/6/819282b4-182f-432b-b99b-a983913e3fd0.pdf"
                                        target="_blank">
                                        Patient Booklet
                                    </a>
                                </div>
                                <div>
                                    <a
                                        // style="color: blue; background-color: transparent; text-decoration: underline;"
                                        href="https://image.mc.lilly.com/lib/fe9112737665057472/m/6/27b1e5a9-d90e-4b29-a64f-42ca9ffc0150.pdf"
                                        target="_blank">
                                        Tips to Help You Manage Side Effects
                                    </a>
                                </div>
                            </div>
                        </Alert>
                    </Grid>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <Card className={styles.baseModal}>
            <CardContent>
                <h3 id="modal-title">Select pack Size</h3>
                {/* <p id="modal-description">donde esta la biblioteca</p> */}
                <Grid container className={classes.containerMargin}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Product Name</StyledTableCell>
                                    <StyledTableCell align="center" size="small" className={styles.tablePackSizeWidth}>
                                        Pack Size
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell align="center">
                                        <TextField
                                            className={classes.textFieldMaxWidth}
                                            disabled
                                            id="packsize"
                                            variant="outlined"
                                            value={props.ProductName}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center" size="small" className={styles.tablePackSizeWidth}>
                                        <TextField
                                            className={classes.textFieldMaxWidth}
                                            disabled
                                            id="quantity"
                                            variant="outlined"
                                            value={props.Product.Name}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {console.log(props.diplayType)}
                <Grid container className={styles.selectPackSizeContainerMargin}>
                    <Grid item xs={12} sm={9}>
                        {props.diplayType == 'HCP'
                            ? diplayQuotaOrConfiramtion(props)
                            : displayColdChainInform(props)
                        }
                    </Grid>
                    <Grid container item xs={12} sm={3}>
                        <FormControl variant="outlined" className={styles.GlobalFormControlMaxWidth}>
                            <InputLabel htmlFor="selectAffiliate">Quantity</InputLabel>
                            <Select
                                id="selectQuantity"
                                variant="outlined"
                                defaultValue={''}
                                error ={ additionalCheckBoxError }
                                className={styles.GlobalFormControlMaxWidth}
                                label="Quantity"
                                onChange={(event) => setQuantity(event.target.value)}>
                                {props.Product.Quantities.map((quant, j) => {
                                    return (
                                        <MenuItem key={j + 1} value={quant}>
                                            {quant}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                           
                            {additionalCheckBoxError == true ? "Please Select Quantity !!" : ''}
                        </FormControl>
                    </Grid>
                </Grid>
                
                <Grid container direction="row-reverse" justifyContent="flex-start" className={classes.containerRightPadding}>
                    
                    <Button style={{backgroundColor:"#d52b1e", color:"white",}} size="large" variant="contained" onClick={handleSubmit}>
                        Submit
                    </Button>
                    
                                {_affiliateCode !== 'ES' ? (
                                    ''
                                ) : (
                                    <div
                                        style={{
                                            color: '#45BA7F',
                                            marginLeft: 5,
                                            marginTop: 10,
                                            marginRight: '39%'
                                        }}>
                                        ■ Available{' '}
                                    </div>
                                )}
                                <div
                                    style={{
                                        color: '#46F0F7',
                                        marginLeft: 5,
                                        marginTop: 10,
                                    }}>
                                    ■ Delivered{' '}
                                </div>
                                <div
                                    style={{
                                        color: '#DB144F',
                                        marginLeft: 5,
                                        marginRight: 15,
                                        marginTop: 10,
                                    }}>
                                    ■ InTransit{' '}
                                </div>
                
                </Grid>
            </CardContent>
        </Card>
    );
});

export default AddPackSize;
