import React from "react";
import ReactDOM from "react-dom";
// import Pdf from "react-to-pdf";
import styles from "../../../css/baseStyle.css";
import FormInputBlock from "../../common/components/formInputBlock";
import FormSelectBlock from "../../common/components/formSelectBlock";
import MuiAlert from "@material-ui/lab/Alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import OnClosureModal from "../../common/components/onClosureModal";
import { useHistory } from "react-router-dom";
// import AuditTrailTable from "../components/AuditTrail";

// import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { Order } from "../../../Store/idb/baseDB";
import axios from "axios";
import {
  Button,
  Typography,
  Paper,
  FormHelperText,
  Chip,
  Grid,
  Checkbox,
  CircularProgress,
  StylesProvider,
  Snackbar,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  depotPaper: {
    padding: '3%',
    marginBottom: '2%',
    paddingBottom: '1%',
    paddingTop: '1%',
  },
}))
const options = {
  orientation: "landscape",
};
class GetFile {
  constructor() {
    this.savedResponse = "";
    this.token = "";
    this.fileName = "";
    this.folderName = "";
    this.subFolderName = "";
    this.submitterId = "";
    this.orderId = "";
  }
}
class SaveFile {
  constructor() {
    this.file = "";
    this.token = "";
    this.fileName = "";
    this.folderName = "";
    this.subFolderName = "";
    this.submitterId = "";
    this.orderId = "";
  }
}

const ref = React.createRef();

const getUniqueFileName = (fileName, fileNameArr) => {
  let resultFileName = fileName;

  if (fileNameArr.includes(fileName)) {
    let nameArr = fileName.split(".");
    if (nameArr.length > 1)
      nameArr[nameArr.length - 2] = nameArr[nameArr.length - 2] + "(1)";
    else if (nameArr.length > 0) nameArr[0] = nameArr[0] + "(1)";
    else "NewFile";

    resultFileName = getUniqueFileName(nameArr.join("."), fileNameArr);
  }
  return resultFileName.replace("/", "").replace("\\", "");
};

const generateBUMenuItems = (props) => {
  return props.FormDetails.BU.map((unit, i) => {
    return (
      <MenuItem key={i} value={unit.ID}>
        {unit.Name}
      </MenuItem>
    );
  });
};

const generateBranchOfficeItems = () => {
  const branchOffices = [
    { id: "1", name: "Branch Office 1" },
    { id: "2", name: "Branch Office 2" },
    { id: "3", name: "Branch Office 3" },
  ];
  console.log("Branch Oddices");
  return branchOffices.map((i) => {
    return (
      <MenuItem key={i.id} value={i.id}>
        {i.name}
      </MenuItem>
    );
  });
};

const handleAttachmentClick = (event, props) => {
  // console.log(
  //   "---------handleAttachmentClick--------------------------",
  //   event,props
  // );
  let model = props.states.attachmentModel.get[event.filename ?? event];
  model.token= props.states.session.get._currentUser.awsToken;
  
  axios
    .post("/api/order/post", model, {
      headers: {
        URL: "api/sampleorderonline/GetFile",
        // "content-type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      },
    })
    .then((data) => {
      // console.log(
      //   "--------handleAttachmentClick-------data------------",
      //   data.data
      // );
      var a = document.createElement("a");
      a.setAttribute("id", "vns");
      document.body.appendChild(a);
      a.style = "display: none";
      var i, l, d, array;
      d = data.data;
      l = d.length;
      array = new Uint8Array(l);
      for (var i = 0; i < l; i++) {
        array[i] = d.charCodeAt(i);
      }
      var b = new Blob([array], { type: "application/octet-stream" });
      let url = URL.createObjectURL(b);
      a.href = url;
      a.download = model.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      var elem = document.getElementById("vns");
      elem.parentNode.removeChild(elem);
    })
    .catch((err) =>
      console.log("--------handleAttachmentClick---------err-------------", err)
    );
  // console.log(
  //   "---------handleAttachmentClick------------model--------------",
  //   model
  // );
  console.info("You clicked the Chip.");
};

const formTemplate = (props) => {
  const classes = useStyles();
  // console.log("Form Template", props);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [typeSnackBar, setTypeSnackBar] = React.useState("info");
  const [textSnackBar, setTextSnackBar] = React.useState("An info message");
  const [contentChange, setContentChange] = React.useState(1);
  const [showClosure, setShowClosure] = React.useState(false);
  const [attachmentDictionary, SetAttachmentDictionary] = React.useState({});
  const [disableButtons, setDisableButtons] = React.useState(false);
  const [closureFunction, setClosureFunction] = React.useState(() => (x) => x);
  const [closureMessage, setClosureMessage] = React.useState();
  const disableSubmit =
    props.states?.DisableSubmitButton != null
      ? props.states?.DisableSubmitButton.get
      : false;
  const disableSave =
    props.states?.DisableSaveButton != null
      ? props.states?.DisableSaveButton.get
      : false;

  const _affiliateCode = window.location.pathname.split("/")[1];

  let history = useHistory();
  const handelClosureDilogeClose = (value) => {
    setDisableButtons(false);
    setShowClosure(value);
  };

  const handleAttachmentDelete = (attachment, props) => {
    console.info(attachment);
    let dictionary = props.states.attachmentModel.get;
    console.info(dictionary);
    // console.log(props.attachments.get);
    delete dictionary[attachment];

    props.states.attachmentModel.set(dictionary);
    props.attachments.set((pval) => {
      let tempArr = pval;
      let index = tempArr.indexOf(attachment);
      tempArr.splice(index, 1);
      return tempArr;
    });
    setContentChange((pval) => pval - 1);
  };

  const listofChips = (props) => {
    // Logic for India Affiliate on ReOpen
    if (_affiliateCode == "IN" && (props.toReClosure || props.toReOpen)) {
      // console.log("Attachments are", props.attachments.get);
      if (props.attachments && props.attachments.get.length > 0) {
        return (
          <div>
            {props.attachments.get.map((attachment) => {
              // console.log(attachment);
              return (
                <Chip
                  label={
                    attachment.filename != null
                      ? attachment.filename
                      : attachment
                  }
                  onClick={(event) => handleAttachmentClick(attachment, props)}
                  onDelete={
                    attachment.disable
                      ? null
                      : (event) => handleAttachmentDelete(attachment, props)
                  }
                  className={styles.formAttachmentsChips}
                />
              );
            })}
          </div>
        );
      } else {
        return <p>No Attachments to display</p>;
      }
    }
    // Logic for India Affiliate on ReOpen Ends
    else {
      if (props.attachments && props.attachments.get.length > 0) {
        return (
          <div>
            {props.attachments.get.map((attachment) => {
              return (
                <Chip
                  label={
                    attachment.filename != null
                      ? attachment.filename
                      : attachment
                  }
                  onClick={(event) => handleAttachmentClick(attachment, props)}
                  onDelete={
                    props.isDisabled
                      ? null
                      : (event) => handleAttachmentDelete(attachment, props)
                  }
                  className={styles.formAttachmentsChips}
                />
              );
            })}
          </div>
        );
      } else {
        return <p>No Attachments to display</p>;
      }
    }
  };

  const handelSave = () => {
    props.toSave().then(() => {
      setTextSnackBar("From sucessfully added to drafts!!");
      setTypeSnackBar("info");
      setOpenSnackBar(true);
    });
  };
  const handleClick = async (type) => {
    setDisableButtons(true);

    let allowSubmit = true;
    ((props.attachments && props.attachments.get) ?? []).forEach((att) => {
      let compareStr = "";
      if (typeof att == "string") {
        compareStr = att;
      } else {
        compareStr = att.filename;
      }
      allowSubmit =
        props.states.attachmentModel.get[compareStr] == null
          ? false
          : allowSubmit;
    });
    if (allowSubmit) {
      switch (type) {
        case "submit":
          // console.log(
          //   "---formTemplate----------handleClick-------submit-------------",
          //   props.submitFunction
          // );
          // let allowSubmit = true;
          // (props.attachments.get ?? []).forEach(att => {
          //     allowSubmit = props.states.attachmentModel.get[att] == null ? false :  allowSubmit;
          // })
          if (props.validate == null || props.validate()) {
            if (props.pageId == "offline") {
              await props.submitFunctionOffline();
              setTextSnackBar("Form submitted successfully!");
              setTypeSnackBar("success");
              setOpenSnackBar(true);
            } else {
              props
                .submitFunction()
                .then((status) => {
                  if (status) {
                    setTextSnackBar("Form submitted successfully!");
                    setTypeSnackBar("success");
                  } else {
                    // console.log('-------something---else-----check',status)
                    setTextSnackBar("Something went Wrong please try again!");
                    setTypeSnackBar("error");
                  }
                  setOpenSnackBar(true);
                  return;
                })
                .catch((err) => {
                  console.log("--------Form Error--------------------------",err);
                  setTextSnackBar("Something went Wrong please try again!");
                  setTypeSnackBar("error");
                  setOpenSnackBar(true);
                  return;
                });
            }
          } else {
            setTextSnackBar("Kindly fix the highlighted errors!");
            setTypeSnackBar("error");
            setOpenSnackBar(true);
          }

          break;
        case "reopen":
          if (props.validate == null || props.validate()) {
            setClosureMessage("Are you sure you want to reopen the Order?");
            const reopen = () => {
              props.toReOpen();
              setTextSnackBar("Form Reopened!");
              setTypeSnackBar("success");
              setOpenSnackBar(true);
            };
            setClosureFunction(() => reopen);
            setShowClosure(true);
          } else {
            setTextSnackBar("Kindly fix the highlighted errors!");
            setTypeSnackBar("error");
            setOpenSnackBar(true);
          }
          break;
        case "reclose":
          if (props.validate == null || props.validate()) {
            setClosureMessage("Are you sure you want to reclose the Order?");
            const reclose = () => {
              props.toReClosure();
              setTextSnackBar("Form ReClosed!");
              setTypeSnackBar("success");
              setOpenSnackBar(true);
            };
            setClosureFunction(() => reclose);
            setShowClosure(true);
          } else {
            setTextSnackBar("Kindly fix the highlighted errors!");
            setTypeSnackBar("error");
            setOpenSnackBar(true);
          }
          break;
        case "resubmit":
          setClosureMessage("Are you sure you want to resubmit the Order?");
          const resubmit = () => {
            props.handelResubmit();
            setTextSnackBar("Form Resubmitted!");
            setTypeSnackBar("success");
            setOpenSnackBar(true);
          };
          setClosureFunction(() => resubmit);
          setShowClosure(true);
          break;
        case "cancel":
          setClosureMessage(
            "Are you sure you want to mark order as Cancelled?"
          );
          const cancel = () => {
            props.handelCancel();
            setTextSnackBar("Form Cancelled!");
            setTypeSnackBar("warning");
            setOpenSnackBar(true);
          };
          setClosureFunction(() => cancel);
          setShowClosure(true);
          // setTextSnackBar('Form deleted!');
          // setTypeSnackBar('warning');
          break;
        case "delete":
          setClosureMessage("Are you sure you want to delete the Order?");
          const toDelete = () => {
            props.toDelete();
            setTextSnackBar("Form Deleted!");
            setTypeSnackBar("warning");
            setOpenSnackBar(true);
          };
          setClosureFunction(() => toDelete);
          setShowClosure(true);
          // setTextSnackBar('Form deleted!');
          // setTypeSnackBar('warning');
          break;
        case "back":
          setClosureMessage("Are you sure you want to go back to home page?");
          setClosureFunction(() => () => history.replace("../../../Home"));
          setShowClosure(true);
          // setTextSnackBar('Form deleted!');
          // setTypeSnackBar('warning');
          break;
        case "error":
          setTextSnackBar("Form deleted!");
          setTypeSnackBar("warning");
          setOpenSnackBar(true);
          break;
        case "save":
          if (props.toSave() == "error") {
            setTextSnackBar("Kindly fix the highlighted errors!");
            setTypeSnackBar("error");
          } else {
            setTextSnackBar("Saving to local drafts.!");
            setTypeSnackBar("success");
          }
          setOpenSnackBar(true);
          break;
        case "print":
          setTextSnackBar("PDF generated!!");
          setTypeSnackBar("success");
          // props.toPrint();
          setOpenSnackBar(true);
          break;
      }
    } else {
      setTextSnackBar("Attachment upload is in progress! Please try again!");
      setTypeSnackBar("error");
      setOpenSnackBar(true);
    }
  };
  const LoadFile = (file, fileName) => {
    let reader = new FileReader();
    reader.onload = function () {
      console.log("-------------onload---------------------------");
      let model = new SaveFile();
      model.file = this.result;
      model.fileName = fileName;
      model.folderName = props.states.folderId;
      model.subFolderName = props.states.orderIdLatest;
      model.submitterId = props.baseDetails.ID;
      model.orderId = props.states.orderIdLatest;
      model.token = props.states.session.get._currentUser.awsToken;
      //    let model = new FormData();
      let _blob = this.result; //new Blob([this.result]);
      // let dict = attachmentDictionary;
      // dict[model.fileName] = model;
      // SetAttachmentDictionary(dict);
      // props.states.attachmentModel.set(dict);
      // model.append('file',_blob);
      // console.log(
      //   "-------------onload----------binaryString-----------------",
      //   model
      // );
      axios
        .post("/api/order/post", model, {
          headers: {
            URL: "api/sampleorderonline/SaveFile",
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            // "content-type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
          },
          maxContentLength: 5242889000,
          maxBodyLength: 5242889000,
        })
        .then((res) => {
          // console.log("---------------SaveFile--------------", res.data);
          let savedModel = new GetFile();
          savedModel.savedResponse = res.data;
          // console.log(
          //   fileNameStore[fileNameStore.length - 1],
          //   fileNameStore
          // );
          savedModel.fileName = fileName;
          savedModel.folderName = props.states.folderId;
          savedModel.subFolderName = props.states.orderIdLatest;
          savedModel.submitterId = props.baseDetails.ID;
          savedModel.orderId = props.states.orderIdLatest;
          savedModel.token = props.states.session.get._currentUser.awsToken;
          if (
            (props.Disabled || props.disableOldAttachments) &&
            props.hasAdminAccess
          )
            // if ((props.Disabled || props.disableOldAttachments) && props.allowUploadAttachment)
            savedModel.uploadedNow = true;

          let dict = props.states.attachmentModel.get;
          dict[model.fileName] = savedModel;
          props.states.attachmentModel.set(dict);
          setContentChange((pval) => pval + 1);

          return res;
        })
        .catch((err) => {
          console.log(
            "-----------------------------------SaveFile Failed",
            err
          );
          return err;
        });
    };
    reader.readAsBinaryString(file);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDisableButtons(false);
    setOpenSnackBar(false);

    if (typeSnackBar == "success" || typeSnackBar == "warning") {
      if (props.refreshFunc != null) {
        props.refreshFunc()
      } else {
        history.replace(props.redirectTo ?? "../Home");
      }
    }
  };

  return (
    <div ref={ref}>
      <Grid container className={styles.formTemplateMarginBottom}>
        {_affiliateCode == "TW" ?
          <Paper elevation={4} className={classes.depotPaper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label='Form ID'
                  value={props.baseDetails.FormID ?? "New Submission"}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label='Submitter Lilly ID'
                  value={props.baseDetails.ID}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label='Submitter Name'
                  value={props.baseDetails.Name ? props.baseDetails.Name : ""}
                  isRequired={true}
                  isDisabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  label='Submitter Email'
                  value={
                    props.baseDetails.Email ? props.baseDetails.Email : ""
                  }
                  isRequired={true}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
          </Paper> :
          <>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                label='Form ID'
                defaultValue={props.baseDetails.FormID ?? "New Submission"}
                isDisabled={true}
                isRequired={true}
              />
            </Grid>
            {_affiliateCode !== 'ES' && <Grid item xs={12} md={6}>
              <FormInputBlock
                label='Submitter Lilly ID'
                defaultValue={props.baseDetails.ID}
                isDisabled={true}
                isRequired={true}
              />
            </Grid>}
            {_affiliateCode !== 'ES' && <Grid item xs={12} md={6}>
              <FormInputBlock
                label='Submitter Name'
                defaultValue={props.baseDetails.Name ? props.baseDetails.Name : ""}
                isRequired={true}
                isDisabled={true}
              />
            </Grid>}
            <Grid item xs={12} md={6}>
              <FormInputBlock
                label='Submitter Email'
                defaultValue={
                  props.baseDetails.Email ? props.baseDetails.Email : ""
                }
                isRequired={true}
                isDisabled={true}
              />
            </Grid>
          </>

        }



        {/* {_affiliateCode === 'JP' ?
                    <Grid item xs={12} md={6}>
                        <FormSelectBlock
                            label="Branch Office"
                            MenuItems={generateBranchOfficeItems()}
                            id="Branch Office"
                            value={props.states.salesRepAddress.Bu.get}
                            setValue={props.states.salesRepAddress.Bu.set}
                            isDisabled={props.readOnly ?? false}
                            isRequired={true}
                            error={props.errors != null ? props.errors.BU.get : false}
                        />
                    </Grid>
                    : ''

                } */}
        {props.baseDetails.DSM != null ? (
          <>
            <Grid item xs={12} md={6}>
              <FormInputBlock
                label='Supervisor'
                defaultValue={props.baseDetails.DSM}
                isRequired={true}
                isDisabled={true}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} /> */}
          </>
        ) : (
          <Grid item xs={12} md={6} />
        )}
        <Grid item xs={12} md={6} />
          <>
            <Grid item xs={12} md={3}>
              {/* <label htmlFor="attachMentTemplate"> */}
              <Button
                component='label'
                variant='contained'
                disabled={props.Disabled && !props.hasAdminAccess}
                color='default'
                startIcon={<CloudUploadIcon />}
              >
                Upload Attachments
                <input
                  hidden
                  id='attachMentTemplate'
                  multiple
                  disabled={props.Disabled && !props.hasAdminAccess}
                  type='file'
                  accept =".pdf, .xlsx, .msg, image/*, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={(events) => {
                    var fileNameStore = [];
                    var initialFiles = props.attachments.get;
                    Array.from(initialFiles).forEach((file) =>{
                        fileNameStore.push(file)
                      }
                    );

                    Array.from(events.target.files).forEach((file) => {

                      var filesize = ((file.size / 1024) / 1024).toFixed(4); // MB
                      if(filesize < 50){
                        // console.log('File size', filesize)
                        let nameUnique = getUniqueFileName(
                          file.name,
                          fileNameStore
                        );
                        // fileNameStore.push({
                        //   filename: nameUnique,
                        //   disable: false,
                        // });
                        // console.log('File size', file.size, filesize)
                        fileNameStore.push(nameUnique);
                        LoadFile(file, nameUnique);
                      }
                      else{
                        window.alert(`File size should be less than 50 MB and is of: ${filesize} MB`);
                      }
                      // console.log("Initial Files", initialFiles, fileNameStore);
                      props.attachments.set(fileNameStore);
                      setContentChange((pval) => pval + 1);
                    });
                  }}
                />
              </Button>
              {/* </label> */}
            </Grid>
            <Grid item xs={12} md={9}>
              {listofChips(props)}
            </Grid>
            {props.attachmentError ? (
              <Grid item xs={12} className={styles.headerBottomPadding}>
                <Typography
                  variant='caption'
                  display='block'
                  gutterBottom
                  className={styles.ColorTextPrimary}
                >
                  *Attachments are required!
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </>
      </Grid>
      <div>{props.children}</div>
      <Grid container justifyContent='center' alignContent='center'>
        {/* <OnClosureModal
          showClosure={showClosure}
          setShowClosure={handelClosureDilogeClose}
          title={closureMessage}
          onYesAction={closureFunction}
          redirectTo={props.redirectTo ?? "../Home"}
        /> */}
        {/* {props.Disabled != true ? (
          <Button
            size='large'
            className={styles.ButtonAlignCenter}
            variant='contained'
            disabled={disableButtons}
            onClick={() => handleClick("back")}
          >
            Back
          </Button>
        ) : (
          <>
            <Button
              size='large'
              className={styles.ButtonAlignCenter}
              variant='contained'
              disabled={disableButtons}
              onClick={() => history.replace(props.redirectTo ?? "../Home")}
            >
              Back
            </Button>
          </>
        )} */}

        {props.hideSave == true || props.Disabled ? (
          ""
        ) : (
          <Button
            size='large'
            className={styles.ButtonAlignCenter}
            variant='contained'
            disabled={_affiliateCode == "IT" ? disableSave : disableButtons}
            onClick={() => handleClick("save")}
          >
            Save
          </Button>
        )}
        {props.toPdf != null ? (
          <Button
            size='large'
            className={styles.ButtonAlignCenter}
            variant='contained'
            disabled={disableButtons}
            onClick={props.toPdf}
          >
            Export to PDF
          </Button>
        ) : (
          ""
        )}

        {props.toDelete != null ? (
          <Button
            size='large'
            className={styles.ButtonAlignCenter}
            variant='contained'
            disabled={disableButtons}
            onClick={() => handleClick("delete")}
          >
            Delete
          </Button>
        ) : (
          ""
        )}

        {props.toReOpen ? (
          <Button
            size='large'
            variant='contained'
            disabled={disableButtons}
            className={`${styles.ButtonAlignCenter} ${disableSubmit ? "" : styles.ColorbackgroundPrimary
              }`}
            onClick={() => handleClick("reopen")}
          >
            Re-Open
          </Button>
        ) : (
          ""
        )}

        {props.toReClosure && props.hasAdminAccess ? (
          <Button
            size='large'
            variant='contained'
            disabled={disableButtons}
            className={`${styles.ButtonAlignCenter} ${disableSubmit ? "" : styles.ColorbackgroundPrimary
              }`}
            onClick={() => handleClick("reclose")}
          >
            Re-Closure
          </Button>
        ) : (
          ""
        )}

        {(props.submitFunction || props.submitFunctionOffline) &&
          (props.Disabled != true || props.hasAdminAccess) ? (
          <Button
            size='large'
            variant='contained'
            disabled={_affiliateCode == "IT" ? disableSubmit : disableButtons}
            onClick={() => handleClick("submit")}
            className={`${styles.ButtonAlignCenter} ${disableSubmit ? "" : styles.ColorbackgroundPrimary
              }`}
          >
            Submit
          </Button>
        ) : (
          ""
        )}
        {props.hasAdminAccess && props.allowCancel ? (
          <Button
            size='large'
            variant='contained'
            disabled={disableButtons}
            onClick={() => handleClick("cancel")}
            className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundTritary}`}
          >
            Cancel
          </Button>
        ) : (
          ""
        )}
        {props.hasAdminAccess == true && props.allowResubmit ? (
          <Button
            size='large'
            variant='contained'
            disabled={disableButtons}
            onClick={() => handleClick("resubmit")}
            className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}
          >
            Resubmit
          </Button>
        ) : (
          ""
        )}
        {props.Disabled == true ? (
          <>
            <Button
              size='large'
              className={styles.ButtonAlignCenter}
              variant='contained'
              disabled={disableButtons}
              onClick={() => window.print()}
            >
              Print
            </Button>
          </>
        ) : (
          ""
        )}
      </Grid>
      
      {/* {props.auditTrail !== null ? (
        <AuditTrailTable auditTrail={props.auditTrail} moduleId={props.moduleId} orderId={props.orderId} />
      ) : (
        ""
      )} */}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleClose}
          severity={typeSnackBar}
        >
          {textSnackBar}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default formTemplate;
