import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Slide from "@material-ui/core/Slide";
// import our react pages
import MainRouter from './baseRouter';
import createstore from './Store/store';
// import SalesRepFormMain from './components/form/OnlineFormSalesRep/index';
import './worker';
import './css/style.css';
import { SnackbarProvider } from 'notistack';
/*
    Usage: Nest your react routes under the switch route

    <Switch>
      <Route path="/cool-route" component={CoolRoute} />
    </Switch>

    In this example, this would render the Home page inside the Template accessible by visiting
    the root route.
*/

const _store = createstore;
ReactDOM.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    TransitionComponent={Slide}
  >
  <Router>
    <Provider store={_store}>
      <MainRouter />
    </Provider>
  </Router>
  </SnackbarProvider>,
  document.getElementById('app')
);
